@media (min-width: 800px) {
  /* 轻量化平台 */
  .pro_Lightweight {
    width: 1400px;
    text-align: center;
    font-size: 22px;
    overflow: hidden;
  }
  .pro_Lightweight > div:nth-child(1) {
    border-right: 6px solid #1ba1e2;
    padding-left: 455px;
    width: 50%;
  }
  .pro_Lightweight > div:nth-child(2) {
    padding-right: 385px;
    width: 50%;
  }
  .pro_Lightweighttext {
    font-size: 15px;
  }
  .pro_Lightweightnone {
    display: none;
  }
  .lightweight_title {
    margin-bottom: 20px;
  }
  .pro_Lightweight_two {
    color: #cccccc;
  }
  .lightweight_title {
    float: left;
    box-sizing: border-box;
    cursor: pointer;
  }
  .pro_Lightweight_one {
    color: #0d0d0d;
  }
  .pro_Lightweight h1 {
    font-weight: bolder;
  }

  /* 最新消息 */
  .pro_new_tittle h1 {
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    position: absolute;
    left: 45%;
    top: 0px;
  }
  .pro_new_img {
    position: relative;
  }
  .pro_new_img img {
    width: 1400px;
  }
  .automovie {
    width: 360px !important;
    position: absolute;
    right: 50px;
    top: 360px;
  }

  /* 客户 */
  .pro_costomer_title h1 {
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    position: absolute;
    left: 47%;
    top: 50px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .pro_Lightweight {
    font-size: 40px;
    width: 90%;
  }
  .pro_Lightweight > div:nth-child(1) {
    padding-left: 390px;
  }
  .pro_Lightweight > div:nth-child(2) {
    padding-right: 200px;
  }
  .pro_Lightweighttext {
    font-size: 47px;
  }
  .pro_new_img {
    width: 90%;
    margin: auto;
  }
  .pro_new_img img {
    width: 100%;
  }
  .pro_new_tittle h1 {
    font-size: 60px;
    top: -70px;
  }
  .automovie {
    top: 500px;
  }
  .pro_costomer_title h1 {
    font-size: 60px;
  }
  .pro_new_tittle h1 {
    top: 0px;
    font-weight: bolder;
  }
}
