@media (min-width: 800px) {
  .tech {
    width: 1400px;
    /* display: flex; */
    justify-content: space-between;
    position: relative;
    margin-top: 100px;
    height: 410px;
    /* overflow: hidden; */
  }

  .tech::after {
    content: "";
    display: block;
    clear: both;
  }

  .tech img {
    object-fit: cover;
    width: 325px;
    height: 185px;
    z-index: -1;
    /* opacity: 0.5; */
    box-shadow: 5px 5px 5px rgba(153, 153, 153, 0.863);
  }

  .tech_title {
    position: absolute;
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    top: 20px;
    left: 45%;
  }

  .tech > div:first-child {
    width: 670px;
    height: 400px;
    margin-left: 0px;
  }

  .tech > div:first-child div {
    top: 85%;
    padding: 10px 30px;
    width: 670px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
  }

  .tech > div:first-child div h1 {
    color: white;
    font-size: 19px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 101;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .tech > div:first-child div p {
    font-size: 15px;
    color: white;
    z-index: 99;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tech > div:first-child img {
    width: 100%;
    height: 100%;
  }

  .tech > div {
    width: 325px;
    height: 194px;
    float: left;
    margin-left: 38px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 3px;
  }

  .tech > div:nth-child(5) {
    margin-bottom: 0;
  }

  .tech > div:nth-child(4) {
    margin-bottom: 0;
  }

  .tech > div:hover {
    transform: scale(1.1);
  }

  .tech > div > div {
    position: absolute;
    top: 73%;
    padding: 0px 15px;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
    z-index: 101;
    width: 325px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
  }

  .tech > div > div > h1 {
    color: white;
    font-size: 12px;
    margin-top: 7px;
    margin-bottom: 5px;
    line-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tech > div > div > p {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
  }
  .homeLoading {
    width: 1400px;
    height: 406px;
    text-align: center;
    font-size: 14px;
  }
  .homeLoading img {
    width: 60px;
    margin-bottom: 10px;
    margin-top: 200px;
  }
}

@media (min-width: 400px) and (max-width: 1000px) {
  .tech_title {
    font-size: 60px;
    left: 43%;
    top: 50px;
  }
  .tech {
    margin-top: 160px;
    width: 100%;
    height: 4000px;
  }
  a:active, a:hover {
    text-decoration: none;
    outline: 0;
  }
  .tech > div:hover  {
    transform: none;
  }
  .tech > div {
    width: 90% !important;
    height: 745px !important;
    margin: auto;
    margin-left: 5% !important;
    margin-bottom: 40px !important;
  }
  .tech > div img {
    height: 745px !important;
    width: 100%;
  }
  .tech > div > div {
    width: 100% !important;
    height: 345px;
    top: 400px !important;
  }
  .tech > div:first-child div h1 {
    margin-top: 210px !important;
  }
  .tech > div > div > h1 {
    font-size: 59px !important;
    line-height: 69px !important;
    margin-top: 220px !important;
  }
  .tech-link-container {
    display: block;
    width: 100%;
    height: 100%;
  }
}
