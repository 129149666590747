@media (min-width: 800px){
  .var_big {
    width: 1400px;
    height: 700px;
    position: relative;
  }
  .vs_text {
    overflow: hidden;
    width: 1400px;
    height: 90px;
  }
  
  .pro_vsimg {
    float: left;
    width: 155px;
    height: 70px;
    margin-right: 50px;
    margin-top: 10px;
  }
  
  
  .pro_vs span {
    margin-top: 90px;
  }
  
  .vs_text a {
    float: left;
    margin-top: 48px;
    font-size: 15px;
    color: #333;
    font-weight: 400;
    margin-left: 30px;
  }
  
  .varcar_text {
    position: absolute;
    z-index: 99;
    width: 1400px;
    color: #042e4e;
    height: 700px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 30%, #f5f7f8 130%);
  }
  .varcar_text_box {
    width: 700px;
    margin-top: 300px;
    padding-left: 90px;
  }
  
  .varcar_text_title {
    display: block;
    font-size: 30px;
    font-weight: bolder;
    line-height: 77px;
    color: #042e4e;
  }
  
  .varcar_text p:hover {
    text-decoration: underline;
  }
  
  .varcar_text p {
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
  }
  .vs_long {
    width: 100%;
    height: 670px;
    background-color: #f0f0f0;
  }
  
  .vs_long_text {
    height: 800px;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .vs_contact_center {
    width: 1400px;
  }
  
  .vs_contact_center::before {
    content: "";
    display: table;
    clear:both;
  }
  
  
  
  .vs_contact_center > div {
    float: left;
    margin-top: 45px;
  }
  
  .vs_contact_right {
    width: 600px;
    margin-left: 70px;
  }
  
  .vscontact_img {
    width: 683px;
    height: 290px;
  }
  
  .vs_contact_right h1 {
    font-size: 38px;
    font-weight: bolder;
    margin-bottom: 25px;
  }
  
  .vs_contact_right p {
    font-size: 19px;
    line-height: 33px;
  }
  /* .contact_video {
    width: 17.07rem !important;
    height: 9.6rem !important;
  } */
  .vs_contact_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .vs_contact_btn {
    text-align: center;
  }
  
  .vs_contact_btn p {
    font-size: 24px;
    color: #ff8000;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 30px;
  }
  
  .vs_contact_btn > div {
    width: 172px;
  }
  
  .vs_contact_btn button {
    border: 3px solid #ff8000;
    color: #ff8000;
    width: 172px;
    font-size: 20px;
    padding: 7px 40px;
    background-color: #f0f0f0;
    border-radius: 40px;
  }
  
  .vs_contact_background{
    background-color: #f0f0f0;
    width: 100%;
    height: 300px;
  }
  
  .vs_contact_four {
    width: 1400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    position: relative;
    margin: auto;
    height: 200px;
    margin-top: 80px;
  }
  
  .vsfour_img {
    width: 97px;
  }
  
  .vs_contact_four > div {
    width: 350px;
    text-align: center;
  }
  
  .vs_before {
    width: 350px;
    position: relative;
  }
  
  .vs_before::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 40px solid #eeeeee;
    border-left: 125px solid transparent;
    border-right: 125px solid transparent;
    position: absolute;
    z-index: 101;
    top: 55px;
    left: 60px;
  }
  
  .vs_bigpic {
    display: none;
  }
  
  .vs_contact_pic {
    width: 1200px;
    margin: auto;
  }
  
  .vs_contact_pic img {
    width: 800px;
    margin-top: 50px;
    margin: auto;
    margin-left: 16%;
  }
  .vs_four_text > div {
    height: 230px;
  }
  .vs_four_text p {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 17px;
  }
  .vs_four_text img {
    cursor: pointer;
    transition: all 0.1s;
  }
  .vs_four_text img:hover {
    transform: scale(1.1);
  }
  .Free_trial {
    width: 1200px;
    overflow: hidden;
  }
  .Free_trial h1 {
    font-size: 28px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  .Free_trial p {
    font-size: 16px;
  }
  .Free_trial span {
    font-weight: bolder;
  }
  .Free_trial button {
    font-size: 15px;
    float: right;
    border: 3px solid #ff8000;
    color: #ff8000;
    border-radius: 10px;
    background-color: white;
    padding: 10px 15px;
  }
  .vs_xian {
    width: 100%;
    height: 2px;
    background-color: #333;
    margin-top: 50px;
  }
  .vs_text {
    overflow: hidden;
    width: 1400px;
    height: 90px;
}

.pro_vsimg {
    float: left;
    width: 155px;
    height: 70px;
    margin-right: 50px;
    margin-top: 10px;
}

.pro_vs {
    float: left;
    height: 48px;
    font-size: 27px;
    font-weight: bolder;
    border-left: 3px solid rgb(204, 229, 255);
    line-height: 48px;
    margin-top: 23px;
    padding-left: 30px;
    margin-right: 30px;
}

.pro_vs span {
    margin-top: 90px;
}

.vs_text a {
    float: left;
    margin-top: 48px;
    font-size: 15px;
    color: #333;
    font-weight: 400;
    margin-left: 30px;
}

/* 最新消息 */
.vs_new h1 {
    font-size: 30px;
    font-weight: bolder;
    color: #05304F;
    padding: 5px 15px;
    border-bottom: 4px solid #05304F;
    position: absolute;
    left: 47%;
    top: 0px;
}

.vs_costomer_title h1 {
    font-size: 30px;
    font-weight: bolder;
    color: #05304F;
    padding: 5px 15px;
    border-bottom: 4px solid #05304F;
    position: absolute;
    left: 47%;
    top: 50px;
}
  
}
@media (min-width: 400px) and (max-width: 1000px){
  .var_big{
    width: 100%;
    height: 900px;
  }
  .varcar_text{
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, #f5f7f8 200%);
    margin: 0;
  }
  .varcar_text_box{
    width: 1400px;
  }
  .varcar_text_title{
    font-size: 58px;
  }
  .varcar_text p{
    font-size: 45px;
    line-height: 60px;
  }
  .vs_text{
    width: 100% ;
    height: 200px ;
  }
  .pro_vsimg{
    width: 350px;
    height: 150px;
    margin-top: 20px;
  }
  .pro_vs{
    font-size: 56px;
    line-height: 140px;
    height: 100px;
  }
  .vs_text p{
    float: left;
    margin-top: 40px;
  }
  .vs_text a{
    font-size: 30px;
  }
  .vs_long{
    height: 1900px;
    width: 100%;
  }
  .vs_long_text{
    height: 2000px;
  }
  .vs_contact_center{
    width: 100%;
  }
  .vs_contact_right{
    width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
  }
  .vs_contact_right_text{
    width: 46%;
    float: left;
  }
  .vs_contact_btn{
    width: 50%;
    float: left;
  }
  .vs_contact_right h1{
    font-size: 56px;
  }
  .vs_contact_right p{
    font-size: 40px;
    line-height: 55px;
  }
  .vs_contact_btn > div{
    width: 30%;
  }
  .vs_contact_btn button{
    font-size: 40px;
    width: 270px;
    height: 90px;
  }
  .vs_four_text p{
    font-size: 40px;
  }
  .vs_four_text img{
    width: 140px;
  }
  .vs_contact_background{
    height: 400px;
  }
  .vs_before::before{
    border-top: 60px solid #eeeeee;
    border-left: 150px solid transparent;
    border-right:150px solid transparent;
    left: 30px;
  }
  .vs_contact_pic{
    width: 1400px;
  }
  .vs_contact_pic img{
    width: 1400px;
    margin-left: 0;
  }
  .Free_trial h1{
    font-size: 48px;
  }
  .Free_trial p{
    font-size: 39px;
    color: #333;
  }
  .vs_contact_right{
    margin-left: 0 ;
  }
}
