@media (min-width: 800px) {
  .news {
    width: 1400px;
    position: relative;
  }

  .news_text img {
    width: 434px;
    height: 347px;
    object-fit: cover;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 5px 5px 5px rgba(153, 153, 153, 0.863);
  }

  .news_text img:hover {
    transform: scale(1.1);
  }

  .news_text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 70px;
    width: 1450px;
  }

  .news_text > div {
    width: 434px;
    height: 500px;
    margin-right: 45px;
  }

  .news_text h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: bolder;
    margin: 15px 0;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .news_text p {
    font-size: 12px;
    line-height: 19px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: black;
  }

  img {
    border-radius: 3px;
  }

  .ant-menu-title-content {
    color: #042e4e;
  }

  .threeTextLoading {
    width: 1400px;
    height: 1000px;
    text-align: center;
    font-size: 14px;
  }
  .threeTextLoading img {
    width: 60px;
    margin-top: 500px;
  }
}

@media (min-width: 400px) and (max-width: 1000px) {
  .title {
    font-size: 60px;
    top: 30px;
    font-weight: bolder;
  }
  .news {
    width: 100%;
    margin-top: 100px;
  }
  .news_text {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    /* text-align: center; */
  }
  .news_text > div {
    width: 90%;
    height: 100%;
    margin-bottom: 30px;
    margin: auto;
    position: relative;
    margin-bottom: 40px;
  }
  .news_text img {
    width: 100%;
    height: 745px;
    object-fit: cover;
    z-index: -1;
  }
  .news_text h1 {
    font-size: 59px;
    line-height: 69px;
    color: white;
    margin-top: 260px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news_text_box {
    width: 100%;
    height: 400px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
    position: absolute;
    top: 345px;
    padding: 20px;
  }
  .news_text p {
    font-size: 44px;
    line-height: 59px;
    color: white;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .news_text img {
    /* pointer-events: none; */
  }
}
