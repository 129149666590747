/* 轮播 */
.container {
    width: 1400px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-carousel {
    width: 1020px;
    height: 600px;
}

.container_left img {
    width: 1020px;
    height: 600px;
}

.big {
    width: 1020px;
    height: 600px;
}

.last {
    background-color: #05304F !important;
}

.last p {
    color: white !important;
}

.last h1 {
    color: white !important;
}

.container_right {
    width: 378px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container_right>div {
    background-color: #EEEEEE;
    height: 119px;
    padding: 20px 30px;
}

.container_right h1 {
    font-size: 25px;
    font-weight: bolder;
    margin-bottom: 20px;
}

.container_right p {
    font-size: 13px;
}

/* 长块 */
.long {
    width: 100%;
    height: 210px;
    background-color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.long_text>img {
    vertical-align: middle;
}

.long_text>div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 210px;
}

.long_text img {
    height: 70px;
}

.long_one {
    height: 210px;
    width: 490px;
    flex: 1;
    background-color: #B3B3B3;
}

.long_one::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 30px solid #B3B3B3;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    position: absolute;
    z-index: 101;
    top: 209px;
    left: 72px;
}

.long_background {
    background-color: #B3B3B3;
    flex: 1;
}

.long_two {
    height: 210px;
    position: relative;
    flex: 1;
}

.long_two::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 30px solid #B3B3B3;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    position: absolute;
    z-index: 101;
    top: 209px;
    left: 72px;
}

.long_three {
    height: 210px;
    position: relative;
    flex: 1;
}

.long_three::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 30px solid #B3B3B3;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    position: absolute;
    z-index: 101;
    top: 209px;
    left: 72px;
}

.long_four {
    height: 210px;
    position: relative;
    flex: 1;
}

.long_four::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 30px solid #B3B3B3;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    position: absolute;
    z-index: 101;
    top: 209px;
    left: 72px;
}


/* 新闻中心 */
.title {
    font-size: 30px;
    font-weight: bolder;
    color: #05304F;
    padding: 5px 15px;
    border-bottom: 4px solid #05304F;
    position: absolute;
    left: 47%;
    top: 0px;
}

/* 技术中心 */