@media (min-width: 800px){
  .pro_text1 {
    width: 1400px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .pro_text_box {
    width: 1400px;
  }
  .pro_text_one {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .pro_text_one > div {
    width: 650px;
  }
  
  .pro_text_one img {
    object-fit: cover;
    width: 600px;
    height: 450px;
  }
  .pro_text_world {
    margin-top: 55px;
    width: 750px !important;
    /* padding-left: 60px; */
  }
  
  .pro_text_world h1 {
    font-size: 28px;
    font-weight: bolder;
  }
  
  .pro_text_world span {
    border-bottom: 5px solid #333;
  }
  
  .pro_text_world p {
    color: #727272;
    font-size: 15px;
    margin-top: 20px;
    line-height: 29px;
  }
  
  .pro_text_world button {
    font-size: 16px;
    color: white;
    background-color: #3d8bcd;
    border: none;
    padding: 10px 90px;
    border-radius: 5px;
    margin-top: 150px;
    margin-left: 420px;
    cursor: pointer;
  }
  
  .pro_text_two {
    overflow: hidden;
  }
  
  .pro_text_two > div {
    float: left;
    width: 650px;
  }
  
  .pro_text_two img {
    object-fit: cover;
    width: 600px;
    height: 450px;
    margin-left: 150px;
  }
  .pro_text_box_center {
    width: 1400px;
  }
  .pro_text_two_world {
    width: 890px;
    margin-top: 40px;
  }
  
  .pro_text_two h1 {
    font-size: 28px;
    font-weight: bolder;
  }
  
  .pro_text_two p {
    color: #727272;
    font-size: 15px;
    margin-top: 20px;
    line-height: 29px;
  }
  .pro_textnone {
    display: none;
  }
  .pro_text_two span {
    border-bottom: 5px solid #333;
  }
  
  .pro_text_two button {
    font-size: 16px;
    color: white;
    background-color: #3d8bcd;
    border: none;
    padding: 10px 90px;
    border-radius: 5px;
    margin-top: 190px;
    cursor: pointer;
  }
}
@media (min-width: 300px) and (max-width: 1000px){
  .pro_text_box{
    width: 90%;
    margin-bottom: 30px;
  }
  .pro_text_box_center{
    width: 100%;
  }
  .pro_text1{
    width: 100%;
  }
  .pro_text_one{
    width: 100%;
  }
  .pro_text_one > div,.pro_text_two>div{
    width: 50%;
  }
  .pro_text_one img{
    width: 100%;
    height: 700px;
  }
  .pro_text_world h1,.pro_text_two h1{
    font-size: 48px;
  }
  .pro_text_world p,.pro_text_two p{
    font-size: 44px;
    line-height: 56px;
  }
  .pro_text_two_world{
    padding-right: 90px;
  }
  .pro_text_two img{
    margin-left: 0;
    width: 100%;
    height: 600px;
  }
  .pro_text_world{
    margin-top: 0;
  }
}

