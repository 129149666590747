@media (min-width: 800px) {
  .com_long {
    background-color: #eeeeee;
    width: 100%;
    height: 319px;
    display: flex;
    justify-content: center;
  }
  .long_con {
    width: 1400px;
    height: 319px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left_img,
  .right_img {
    width: 136px;
    height: 136px;
  }
  .long_con span {
    font-size: 44px;
    font-weight: bolder;
    color: #ff0080;
    position: absolute;
    top: 199px;
    left: 153px;
  }
  #num {
    font-size: 44px;
    font-weight: bolder;
    color: #ff0080;
    position: absolute;
    top: 199px;
    left: 140px;
  }
  .long_con p {
    font-size: 16px;
    font-weight: bolder;
    margin-top: 220px;
    /* color: #727272; */
  }
  .left_img {
    position: absolute;
    top: 70px;
    left: 40px;
  }
  .right_img {
    position: absolute;
    top: 70px;
    left: 156px;
  }
  .long_con > div {
    position: relative;
    height: 319px;
    width: 350px;
    display: flex;
    justify-content: center;
  }
  .middle_img {
    width: 45px;
    position: absolute;
    top: 112px;
    left: 145px;
  }
}

@media (min-width: 400px) and (max-width: 1000px){
    .com_long{
        height: 100%;
    }
    .long_con{ 
        width: 100%;
        height: 638px;
    }
    .long_con > div{
        width: 25%;
        height: 100%;
    }
    .left_img,
  .right_img{
    width: 272px;
    height: 272px;
  }
  .middle_img{
    width: 90px;
    top: 220px;
    left: 199px;
  }
  .left_img{
    top: 140px;
    left: 20px;
  }
  .right_img {
    top: 140px;
    left: 186px;
  }
  .long_con p{
    margin-top: 440px;
    font-size: 36px;
  }
}
