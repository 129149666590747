@media (min-width: 992px) {
  .client {
    margin-top: 170px;
    position: relative;
    margin-left: 75px;
    width: 14.7rem;
  }

  .client_title {
    position: absolute;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    top: -80px;
    left: 46%;
  }
  .client_title h1 {
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
  }
  .client .image-style:hover {
    transform: scale(1.2);
  }
  .image-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .image-style {
    width: 128px;
    height: 70px;
    border: 1px solid #ededed;
    cursor: pointer;
    transition: all 0.2s;
  }
  .image-jiantou {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    cursor: pointer;
  }
  .image-png {
    width: 1.28rem; 
    height: 0.7rem;
    border: 1px solid #ededed;
    padding: 0.2rem 0.15rem;
    background-color: #ffffff;
  }
  .client .image-png:hover {
    transform: scale(1.2);
  }
  
  .client div:last-child {
    margin-right: 0;
  }

  .nine {
    margin-right: 0 !important;
  }
}

@media (max-width: 992px) {
  .client_title{
    width: 10%;
    padding: 0.05rem 0.15rem;
    position: relative;
    left: 46%;
    text-align: center;
    border-bottom: 4px solid #05304f;
  }
  .client_title h1 {
    font-size: 0.6rem;
    color: #05304f;
    font-weight: bolder;
  }
  .client{
      margin-top: 90px;
      width: 100%;
  }
  .image-row {
    display: flex;
    flex-wrap: wrap;
  }
  .image-row div {
    width: 30%;
    display: flex;
    float: none;
    padding: 0 7.5px;
  }
  .image-style {
    color: #fff;
    border: 1px solid #e0e0e0;
    text-decoration: none;
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .image-png {
    color: #fff;
    border: 1px solid #e0e0e0;
    text-decoration: none;
    padding: 1rem 0.6rem;
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}
