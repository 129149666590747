@media (min-width: 800px) {
  .pro_big {
    width: 1400px;
    height: 700px;
    position: relative;
  }

  .pro_text {
    overflow: hidden;
    width: 1400px;
  }

  .pro_yige {
    float: left;
    font-weight: bolder;
    border-left: 3px solid rgb(204, 229, 255);
    margin-top: 23px;
    padding-left: 30px;
  }
  .pro_yige span {
    height: 48px;
    font-size: 27px;
    line-height: 48px;
  }
  .pro_yige a {
    font-size: 15px;
    margin-left: 60px;
    color: #333;
    font-weight: 400;
  }
  .pro_menu_box {
    float: left;
    width: 150px;
    text-align: center;
  }
  .product_logo_img {
    height: 20px;
    /* margin-right: 50px; */
    cursor: pointer;
  }

  .pro_contact {
    width: 240px;
    padding: 10px 10px;
    vertical-align: middle;
    margin-right: 30px;
    float: left;
  }

  .pro {
    position: relative;
    width: 1400px;
  }

  .procar_text {
    position: absolute;
    z-index: 99;
    width: 1400px;
    color: #042e4e;
    height: 700px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 30%, #f5f7f8 130%);
  }
  .procar_text_box {
    width: 700px;
    margin-top: 300px;
    padding-left: 120px;
  }

  /* 轮播 */
  .slick-dots {
    bottom: 9px;
  }
  .slick-prev {
    z-index: 99;
    left: 20px;
  }
  .slick-next {
    z-index: 99;
    right: 50px;
  }
  .slick-next:before {
    display: inline-block;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
  }
  .pro_car_loading {
    width: 1400px;
    height: 703px;
    text-align: center;
    font-size: 14px;
  }
  .pro_car_loading img {
    width: 70px;
    margin-top: 340px;
    margin-bottom: 10px;
  }

  .procar_title {
    font-size: 30px;
    font-weight: bolder;
    line-height: 77px;
    color: #042e4e;
    display: block;
  }
  .procar_text p:hover {
    text-decoration: underline;
  }

  .procar_text_desc {
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
  }

  .product_logo {
    padding: 10px 0;
    width: 1400px;
    height: 60px;
    background-color: #042e4e !important;
    padding-left: 840px;
    overflow: hidden;
  }
  .product_logo > div {
    float: left;
  }

  .car_bottom {
    width: 87px;
    height: 2px;
    background-color: #001529;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: absolute;
    top: 54px;
    left: 978px;
  }
  .pro_menu {
    /* height: 100px; */
    position: absolute;
    right: 300px;
    z-index: 99;
    line-height: 50px;
  }
  .pro_menu img {
    margin-left: 20px;
  }
  .menu1 a {
    color: #001529;
    font-size: large;
  }

  .menu1 > li {
    box-sizing: border-box;
    height: 30px;
    padding: 0 50px;
  }

  .menu1 a {
    font-size: 14px;
    padding-bottom: 8px;
  }
  .menu1 {
    width: 700px;
    border-bottom: 1px solid #f0f0f0;
    margin-top: 15px;
  }
  .check_img {
    height: 35px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .product_logo {
    width: 100%;
    height: 200px;
  }
  .pro_menu_box{
    width: 440px;
  }
  .pro_menu {
    height: 200px;
    line-height: 260px;
    right: 60px;
  }
  .product_logo_img {
    height: 70px;
    margin-right: 40px;
  }
  .check_img {
    height: 100px;
  }
  .pro_box {
    width: 100%;
  }
  .pro{
    width: 1900px;
  }
  .pro_big{
    width: 100%;
    height: 900px;
  }
  .procar_text{
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, #f5f7f8 200%);
    margin: 0;
  }
  .procar_text_box{
    width: 1400px;
  }
  .procar_text_desc{
    font-size: 45px;
    line-height: 60px;
    font-weight: bolder;
  }
  .procar_title{
    font-size: 58px;
  }
  .pro_text{
    width: 100%;
  }
  .pro_contact{
    width: 500px;
  }
  .pro_yige span{
    font-size: 56px;
    line-height: 100px;
  }
  .pro_yige a{
    font-size: 37px;
  }
  .video_none{
    display: none;
  }

}
