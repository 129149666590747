@media (min-width: 800px) {
  .long {
    width: 100%;
    height: 210px;
    background-color: #eff2f5;
  }
  .long-slide {
    width: 14rem;
    margin-top: 33px;
    position: relative;
    overflow: hidden;
  }
  .slide-contain {
    overflow: hidden;
    scroll-behavior: smooth;
  }
  .long_text {
    height: 244px;
    display: flex;
    flex-wrap: nowrap; 
    justify-content: flex-start;
  }
  .long_text > img {
    vertical-align: middle;
  }
  
  .long_text > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 210px;
    width: 20%;
    flex-wrap: nowrap;
  }

  .long_img {
    height: 210px;
    width: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.1s;
  }
  .long_img_logoactive {
    height: 80px !important;
    width: 230px;
  }
  .long_text > div:last-child img {
    height: 1.2rem !important;
  }

  .long_img_logo {
    height: 70px;
    width: 210px;
  }

  .long_img > img:hover {
    transform: scale(1.1);
  }

  .three {
    position: absolute;
    height: 210px;
    width: 350px;
    z-index: 9999;
  }

  .three::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 30px solid #c9dbee;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    position: absolute;
    z-index: 9999;
    top: 209px;
    left: 72px;
  }

  .long_background {
    background-color: #c9dbee;
  }

  .product_content {
    display: none;
    font-weight: 300;
    line-height: 1.8;
    opacity: 0;
  }

  .product {
    width: 1400px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 12px 0;
  }

  .active {
    display: block;
    color: rgb(14, 13, 13);
  }

  .product_left {
    width: 518px;
    height: 393px;
  }

  .product_left img {
    width: 510px;
    margin-top: 36px;
  }

  .product_right {
    padding-left: 64px;
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    line-height: 24px;
  }

  .right_title {
    font-size: 28px;
    font-weight: 600;
    color: #222222;
    margin-bottom: 10px;
  }

  .product_text {
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    line-height: 24px;
  }

  .product_right li {
    margin-left: 30px;
    line-height: 24px;
    font-size: 13px;
  }

  .icon-triangle-right {
    color: #0066cc;
    font-size: 13px;
    padding-right: 6px;
  }

  .product_right button {
    background-color: #3d8bcd;
    color: white;
    border-style: none;
    padding: 10px 20px;
    border-radius: 6px;
    margin-left: 520px;
    font-size: 14px;
    cursor: pointer;
  }
  .product_buttton_none {
    display: none;
  }
}

@media (min-width: 400px) and (max-width: 1000px) {
  .long {
    width: 100%;
    height: 200px;
  }
  .long-slide{
    width: 100%;
  }
  .long_text {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    height: 234px;
    position: relative;
  }
  .long_text > div {
    height: 180px;
    width: 20%;
  }
  .long_img {
    width: 210px;
    margin: auto;
  }
  .three {
    height: 210px;
  }
  .three::before {
    top: 208px;
  }
  .long_text img {
    width: 300px;
  }
  .long_img_logoactive {
    height: 80px !important;
    width: 230px;
  }
  .long_text > div:last-child img {
    height: 1.2rem !important;
  }
  .product {
    width: 100%;
    height: 1900px;
    display: block;
  }
  .product_left {
    text-align: center;
    width: 100%;
    height: 1000px;
    margin: 30px 0;
  }
  .product_left > img {
    width: 80%;
    margin: auto;
  }
  .product_right {
    width: 80%;
    margin: auto;
  }
  .right_title {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 30px;
  }
  .product_text {
    font-size: 45px;
    line-height: 60px;
  }
  .product_right li {
    font-size: 40px;
    line-height: 55px;
  }
  .icon-triangle-right {
    font-size: 35px;
  }
  .product_right button {
    margin-top: 30px;
    /* margin-left: 25%; */
    margin-left: 0;
    width: 100%;
    font-size: 35px;
    padding: 30px 80px;
    border-radius: 20px;
  }
}
