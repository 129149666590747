@media (min-width: 800px) {
  .world_box {
    width: 1400px;
    height: 500px;
  }
  .world_img {
    float: left;
    margin-top: 22px;
  }
  .world_text {
    float: left;
    width: 930px;
    width: 1400px;
  }
  .world_img img {
    width: 257px;
    height: 75px;
  }
  .world_text_box {
    margin-left: 340px;
    margin-top: 30px;
  }
  .world_text h1 {
    font-size: 33px;
    font-weight: bolder;
    line-height: 40px;
  }
  .world_text p {
    font-size: 15px;
    line-height: 27px;
    color: #727272;
  }
  .world_logobox {
    float: left;
    width: 1400px;
    height: 260px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;
  }
  .world_logobox > div {
    box-sizing: border-box;
    border: 1px solid #333;
    width: 120px;
    height: 120px;
    cursor: pointer;
    transition: all 0.1s;
    text-align: center;
  }
  .world_logobox > div:not(:nth-child(10n + 0)) {
    margin-right: 10px;
  }
  .world_logobox img {
    width: 50px;
    height: 50px;
    margin-top: 19px;
  }
  .world_logobox > div:not(:nth-child(10n + 1)) {
    background-color: rgb(230, 230, 230);
  }
  .world_logobox p {
    line-height: 37px;
    font-weight: bold;
  }
  .world_logobox > div:hover {
    transform: scale(1.2);
  }
}
@media (min-width: 300px) and (max-width: 1000px){
  .world_box{
    width: 90%;
    margin-top: 40px;
    height: 1600px;
  }
  .world_text{
    width: 100%;
  }
  .world_img img{
    width: 430px;
    height: 150px;
    margin-right: 40px;
  }
  .world_text h1{
    font-size: 50px;
    margin-bottom: 16px;
  }
  .world_logobox{
    width: 100%;
  }
  .world_text p{
    font-size: 35px;
    line-height: 45px;
  }
  .world_logobox > div{
    width: 280px;
    height: 280px;
    margin-top: 30px;
  }
  .world_logobox > div:not(:nth-child(5n + 0)){
    margin-right: 30px;
  }
  .world_logobox > div:not(:nth-child(10n + 1)){
    margin-right: 30px;
  }
  .world_logobox img{
    width: 50%;
    height: 50%;
    margin-top: 40px;
  }
}
