@media (min-width: 800px) {
  .banner_box {
    width: 100%;
    height: 310px;
    background-image: url(@images/banner.jpg);
    background-color: #081633;
    background-attachment: fixed;
    background-repeat: repeat-y;
    background-position-x: 50%;
    background-position-y: -10px;
  }
  .background-position {
    background-position-x: 50%;
    background-position-y: -10px;
  }
  .black_overlay {
    width: 100%;
    height: 310px;
    background-image: url(@images/overlay-black.png);
    background-repeat: no-repeat;
    background-position: 0 top;
  }
  .banner_container {
    width: 1200px;
    height: 300px;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .banner_container h1 {
    font-size: 60px;
    font-weight: 700;
    color: #1086df;
    text-transform: uppercase;
  }
  .banner_container h2 {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: none;
    padding: 0px;
    line-height: 25px;
  }
  /* 使命愿景 */
  .mission {
    width: 1400px;
    /* height: 907px; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .mission_top {
    width: 1400px;
    display: table-cell;
    float: left;
    height: 41px;
    margin-bottom: 50px;
  }
  .mission_top img {
    width: 525px;
    height: 85px;
  }
  /* .mission_img {
    width: 530px;
    height: 352px;
  } */
  .mission_img > img {
    width: 530px;
    height: 352px;
  }
  .mission_text {
    width: 870px;
    height: 342px;
    padding: 0 70px;
  }
  .mission_text > h2 {
    font-size: 20px;
    font-weight: bolder;
    margin-bottom: 33px;
    margin-top: 40px;
  }
  .mission_text p {
    font-size: 14px;
    color: #727272;
    line-height: 19px;
  }
  .mission_text span {
    font-weight: bolder;
  }
  .misstion_p {
    line-height: 25px !important;
  }
  .mission_text li {
    margin-top: 20px;
    /* list-style: disc; */
    margin-left: 30px;
    font-size: 14px;
    color: #727272;
    line-height: 19px;
  }

  /* 业务领域 */
  /* .field-logo {
    position: absolute;
    top: 80px;
    right: 14%;
    width: 130px;
    height: 60px;
  }
  .field_title {
    position: absolute;
    font-size: 54px;
    color: #05304f;
    padding: 5px 15px;
    top: 80px;
    left: 13%;
  }
  .field_text {
    overflow: hidden;
    width: 1400px;
    margin-top: 60px;
  }
  .field_text img {
    float: left;
    width: 70px;
    height: 60px;
  }
  .field_text div {
    float: left;
  }
  .field_text h1 {
    font-size: 23px;
    font-weight: bolder;
    color: #05b1f0;
    line-height: 54px;
  }
  /* .field_text p {
    font-size: 14px;
    line-height: 30px;
    color: #727272;
  } */
  /* .field_big {
    margin-top: 10px;
    margin-bottom: 70px;
    width: 1400px;
  }
  .field_one {
    margin-top: 160px;
  }  */
   /* 业务领域 */
   .field_title {
    position: absolute;
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    top: 80px;
    left: 45%;
  } 
  .field_text img {
    float: left;
    width: 100px;
    margin-top: 10px;
  }
  .field_text div {
    font-size: 0.2rem;
    font-weight: 500;
    color: #000000;
    padding: 25px 0;
    margin-left: 3rem;
  }
  .field_text h1 {
    font-size: 20px;
    font-weight: bolder;
    color: #05304f;
    line-height: 90px;
  }
  .field_text p {
    font-size: 14px;
    line-height: 30px;
    color: #727272;
  }
  .field_big {
    margin-bottom: 70px;
    width: 1400px;
  }
  .field_one {
    margin-top: 120px;
  }


  /* 服务能力 */
  .service_title {
    position: absolute;
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
    top: -10px;
    left: 47%;
  }
  .service_bigcontent {
    width: 1400px;
  }
  .service_big {
    background: url("@images/service.jpg") no-repeat center #598db4;
    background-size: auto 100%;
    height: 642px;
    width: 100%;
    margin-top: 144px;
  }
  .service_text {
    width: 100%;
  }
  .service_left {
    width: 800px;
    height: 300px;
    position: relative;
  }
  .servicr_right {
    width: 481px;
    height: 335px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .servicr_right > img {
    position: absolute;
  }
  .python {
    width: 266px;
    height: 75px;
    top: 75px;
    left: 100px;
  }
  .net {
    width: 78px;
    height: 71px;
    top: 75px;
    left: 317px;
  }
  .reactlogo {
    width: 69px;
    height: 69px;
    left: 100px;
    top: 150px;
  }
  .servicr_right span {
    font-size: 68px;
    color: rgb(97, 218, 251);
    background-color: #222222;
    position: absolute;
    top: 151px;
    left: 169px;
  }
  .service_left h1 {
    font-size: 20px;
    font-weight: bolder;
    color: #042e4e;
    margin-top: 100px;
  }
  .service_left p {
    font-size: 14px;
    line-height: 28px;
    margin-top: 26px;
    color: #727272;
  }
  .service_left button {
    width: 134px;
    height: 38px;
    font-size: 14px;
    border: none;
    background-color: #3d8bcd;
    color: white;
    margin-top: 50px;
    border-radius: 8px;
  }
  .service_left_btn {
    position: absolute !important;
    right: 150px;
  }
  .yuan {
    width: 310px;
  }
  .service_one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1400px;
    height: 350px;
  }
}

@media (min-width: 400px) and (max-width: 1000px) {
  .banner_container {
    width: 90%;
    height: 100%;
    padding: 120px 0;
  }
  .banner_container h1 {
    font-size: 65px;
  }
  .banner_container h2 {
    font-size: 45px;
    line-height: 70px;
  }
  .black_overlay {
    height: 570px;
  }
  .banner_box {
    height: 570px;
  }
  .mission {
    width: 90%;
  }
  .mission_top img {
    width: 800px;
    height: 200px;
  }
  .mission_top {
    height: 230px;
    margin: 0;
  }
  .mission_img {
    width: 100%;
  }
  .mission_img > img {
    width: 100%;
    height: 890px;
  }
  .mission_text {
    width: 100%;
    padding: 0;
    height: 100%;
    margin-bottom: 60px;
  }
  .mission_text > h2 {
    font-size: 60px;
    margin: 20px 0;
  }
  .mission_text p {
    font-size: 49px;
    line-height: 60px !important;
  }
  .mission_text li {
    font-size: 45px;
    line-height: 50px;
  }
  /* .field_text_box{
    width: 90%;
  }
  .field_title{
    font-size: 0.8rem;
    left: 5%;
    font-weight: 600;
  }
  .field_text{
    width: 100%;
  }
  .field-logo {
    width: 200px;
    height: 1rem;
    position: absolute;
    right: 6%;
  }
  .field_text h1{
    font-size: 50px;
    color: #05b1f0;
  }
  /* .field_text p{
    font-size: 40px;
    line-height: 60px;
  } */
  /* .field_text div{
    width: 85%;
  }
  .field_one{
    margin-top: 200px;
  }
  .field_big{
    width: 100%;
  }  */
  .field_text_box{
    width: 90%;
  }
  .field_title{
    font-size: 60px;
    left: 43%;
  }
  .field_text{
    width: 100%;
  }
  .field_text>img{
    width: 200px;
  }
  .field_text h1{
    font-size: 50px;
  }
  .field_text p{
    font-size: 40px;
    line-height: 60px;
  }
  .field_text div{
    width: 85%;
  }
  .field_one{
    margin-top: 200px;
  }
  .field_big{
    width: 100%;
  }

  .service_title{
    font-size: 60px;
    left: 43%;
  }
  .service_left h1{
    font-size: 45px;
  }
  .service_left p{
    font-size: 38px;
    line-height: 49px;
  }
  .service_bigcontent{
    width: 90%;
  }
  .service_one{
    width: 100%;
    margin-bottom: 60px;
  }
  .service_left{
    width: 80%;
  }
  .python{
    width: 300px;
    left: 40px;
    height: 100px;
  }
  .net{
    width: 126px;
    height: 100px;
  }
  .reactlogo{
    width: 90px;
    height: 90px;
    top: 190px;
  }
  .servicr_right span{
    font-size: 87px;
    top: 190px;
    left: 190px;
  }
  .yuan{
    width: 350px;
  }
  .service_left>.service_left_title{
    margin-top: 60px;
  }
}
