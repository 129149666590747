@media (min-width: 800px) {
  .pro_con_big {
    width: 100%;
    /* height: 400px; */
    background-color: #eeeeee;
    position: relative;
  }

  .contact_content {
    width: 100%;
    /* height: 400px; */
    /* overflow: hidden; */
  }

  .con_center {
    width: 1400px;
    /* height: 400px; */
  }

  .con_logo {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    /* height: 430px; */
    width: 1400px;
  }

  .con_logo h1 {
    font-size: 28px;
    font-weight: bolder;
    line-height: 60px;
    padding-left: 60px;
  }

  .con_logo_text {
    width: 760px;
    height: 400px;
  }
  .contact_video {
    width: 640px !important;
    height: 360px !important;
  }
  .con_logo p {
    font-size: 18px;
    padding-left: 60px;
    line-height: 28px;
  }
  .con_title {
    font-weight: bold;
    font-size: 12px;
  }
  .con_text {
    font-size: 15px !important;
  }
  .con_logoimg {
    width: 683px;
  }

  .logoimg {
    display: none;
  }

  .con_three_box {
    width: 100%;
    background-color: #eeeeee;
  }
  .con_three {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .con_three > div {
    width: 460px;
    height: 231px;
    text-align: center;
    line-height: 216px;
    position: relative;
  }

  .con_three img {
    width: 220px;
    height: 64px;
    margin-top: 71px;
    cursor: pointer;
    transition: all 0.1s;
  }

  .con_three img:hover {
    transform: scale(1.2);
  }

  .con_bef {
    position: absolute;
    top: -216px;
    left: 0;
    width: 460px;
    height: 206px;
    position: relative;
  }

  .con_bef::before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 40px solid #eeeeee;
    border-left: 125px solid transparent;
    border-right: 125px solid transparent;
    position: absolute;
    z-index: 101;
    top: 207px;
    left: 114px;
  }

  .procontact {
    display: none;
    font-weight: 300;
    line-height: 1.8;
    opacity: 0;
  }

  .morepic {
    height: 690px;
    font-size: 27px;
    margin-top: 90px;
  }

  .morepic_text {
    font-size: 8px;
  }
  .morepic_box {
    width: 1400px;
  }
  .pro_moreimg {
    display: flex;
    justify-content: space-between;
  }

  .pro_littleimg_box::after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
  }

  .pro_littleimg_box > div:nth-child(10) {
    margin-right: 0px;
  }

  .pro_littleimg_box > div:nth-child(20) {
    margin-right: 0px;
  }

  .pro_littleimg_box > div:nth-child(38) {
    margin-right: 0px;
  }

  .pro_littleimg {
    float: left;
    width: 120px;
    height: 120px;
    text-align: center;
    border: 1px solid #333;
    margin-right: 22px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
  }

  .pro_littleimg_box > div {
    cursor: pointer;
    transition: all 0.1s;
  }

  .pro_littleimg_box > div:hover {
    transform: scale(1.2);
  }

  .pro_littleimg_box > div:nth-child(28) {
    margin-right: 161px;
  }

  .pro_littleimg_box > div:nth-child(1) {
    background-color: white;
  }

  .pro_littleimg_box > div:nth-child(11) {
    background-color: white;
  }

  .pro_littleimg_box > div:nth-child(21) {
    background-color: white;
  }

  .pro_littleimg_box > div:nth-child(29) {
    background-color: white;
  }

  .pro_littleimg_box > div:nth-child(39) {
    background-color: white;
  }

  .pro_littleimg img {
    width: 70px;
    height: 70px;
    margin-bottom: 5px;
    margin-top: 12px;
  }

  .pro_littleimg {
    font-size: 16px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .con_center {
    width: 100%;
  }
  .con_logo {
    width: 100%;
    flex-wrap: wrap;
    height: 1800px;
  }
  .contact_video {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 50px;
  }
  .con_logo_text {
    width: 100%;
  }
  .video_box {
    width: 100%;
  }
  .con_logo h1 {
    font-size: 56px;
    font-weight: bold;
  }
  .con_logo p {
    font-size: 40px;
    line-height: 50px;
  }
  .con_text {
    font-size: 36px !important;
    line-height: 50px;
    color: rgb(80, 80, 80);
  }
  .con_three > div {
    width: 30%;
  }
  .con_three_box {
    height: 250px;
  }
  .con_three img {
    width: 50%;
    height: 44%;
  }
  .con_bef::before {
    left: 150px;
    top: 178px;
    border-top: 60px solid #eeeeee;
    border-left: 150px solid transparent;
    border-right:150px solid transparent;
  }
  .morepic_box {
    width: 90%;
    margin: auto;
  }
  .morepic {
    width: 100%;
    height: auto;
  }
  .pro_littleimg {
    width: 280px;
    height: 280px;
    margin: 10px 30px;
  }
  .pro_littleimg_box > div:nth-child(10),
  .pro_littleimg_box > div:nth-child(20),
  .pro_littleimg_box > div:nth-child(38) {
    margin: 10px 30px !important;
  }
  .pro_littleimg_box > div:nth-child(28) {
    margin-right: 390px;
  }
  .pro_littleimg img{
    width: 50%;
    height: 50%;
    margin-top:40px
  }
  .morepic_text{
    font-size: 35px;
  }
  .pro_littleimg_box > div,.con_three img{
    pointer-events: none;
  }
}
