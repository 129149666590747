@media (min-width: 800px){
    .pro_customer {
        width: 1400px;
        margin-top: 120px;
    }
    
    .pro_customer ul {
        overflow: hidden;
        margin-bottom: 18px;
    }
    
    .pro_customer li {
        float: left;
        margin-right: 40px;
        font-size: 19px;
        font-weight: 550;
        cursor: pointer;
    }
    
    .pro_customerLi {
        color: #5593E6;
        /* border-bottom: 4px solid #333; */
        padding-bottom: 8px;
    }
    
    .pro_customer_Exhibition {
        width: 1400px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .pro_customer_Exhibition div:not(:nth-child(10n+0)) {
        margin-right: 22px;
    }
    
    .customer_img {
        width: 120px;
        cursor: pointer;
        transition: all 0.1s;
    }
    .customer_img:hover{
        transform: scale(1.2);
    }
    
    .pro_customer_Exhibition>div {
        width: 120px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .customer_pic_box {
        width: 120px;
        text-align: center;
        display: block;
    }
    
    
    .pro_customer_Exhibition>div:last-child img {
        height: 63px;
        width: 63px;
    }
    
    .customer_more {
        display: none;
        width: 120px;
    }
}
@media (min-width: 400px) and (max-width: 1000px){
    .pro_customer{
        width: 90%;
        margin-top: 190px;
    }
    .pro_customer_Exhibition{
        width: 100%;
    }
    .pro_customer li{
        font-size: 50px;
    }
    .pro_customer_Exhibition>div{
        width: 20%;
    }
    .pro_customer_Exhibition div:not(:nth-child(5n+0)),.pro_customer_Exhibition div:not(:nth-child(10n+0)){
        margin-right: 0;
    }
    .customer_img{
        width: 80% !important;
    }
    .pro_customer_Exhibition>div:last-child img{
        width: 40% !important;
        height: 40% !important;
    }
}
