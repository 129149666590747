@media (min-width: 800px) {
  .sear_four {
    width: 1400px;
    position: relative;
  }

  .sear_big {
    width: 100%;
  }

  .sear_check_center {
    width: 1400px;
    height: 80px;
    overflow: hidden;
  }

  .search_intput {
    margin-left: 400px;
    margin-top: 20px;
  }

  .sear_check_center > div {
    float: left;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
  }

  .search_background {
    background-color: #b3b3b3;
  }

  .check {
    position: absolute;
    top: -18px;
    right: 0px;
  }

  .sear_check_center img {
    width: 90%;
    height: 60px;
  }

  .sear_check {
    width: 100%;
    height: 80px;
    background-color: #eeeeee;
    margin-top: 20px;
  }

  .sear_four_text img {
    width: 320px;
    height: 212px;
    object-fit: cover;
  }

  .sear_four button {
    background-color: #3d8bcd;
    color: white;
    border-style: none;
    padding: 10px 60px;
    border-radius: 6px;
    font-size: 16px;
    margin-left: 45%;
  }

  .sear_four_text {
    margin-top: 20px;
  }

  .sear_four_text::after {
    content: "";
    display: block;
    clear: both;
  }

  .sear_four_text > div {
    float: left;
    width: 320px;
    height: 330px;
    margin-bottom: 8px;
  }

  .sear_four_text > div:not(:nth-child(4n + 0)) {
    margin-right: 39px;
  }

  .sear_four_text > div img {
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 5px 5px 5px rgba(153, 153, 153, 0.863);
  }

  .sear_four_text > div img:hover {
    transform: scale(1.1);
  }

  .sear_four_text h1 {
    font-size: 16px;
    font-weight: bolder;
    margin: 6px 0 8px 0;
    line-height: 23px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .sear_four_text p {
    font-size: 13px;
    line-height: 19px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #333;
  }

  .ant-input-group-wrapper {
    width: 300px !important;
    margin-left: 550px;
    margin-top: 20px;
  }

  .ant-btn-primary {
    background-color: #ffffff !important;
    color: #b3b3b3 !important;
    border: 1px solid #b3b3b3 !important;
  }
  .sear_loading {
    width: 1400px;
    height: 735px;
    text-align: center;
    font-size: 14px;
  }
  .sear_loading img {
    width: 70px;
    height: 70px;
    margin-top: 370px;
    margin-bottom: 10px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .sear_check {
    height: 190px;
  }
  .sear_check_center {
    width: 91%;
    height: 190px;
  }
  .sear_check_center img {
    height: 140px;
    width: 4rem;
    margin-top: 10px;
  }
  .sear_four {
    width: 90%;
  }
  .sear_four_text > div {
    width: 100%;
    height: 900px;
    position: relative;
  }
  .sear_four_text img {
    width: 100%;
    height: 100%;
  }
  .sear_four_text_box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 20px 20px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
  }
  .sear_four_text h1 {
    font-size: 55px;
    line-height: 65px;
    color: white;
  }
  .sear_four_text p {
    font-size: 45px;
    line-height: 55px;
    color: white;
  }
  .sear_four button{
    padding: 30px 130px;
    margin-left: 38%;
    font-size: 39px;
    border-radius: 30px;
  }
  .sear_four_text > div{
    margin-bottom: 40px;
  }
  .sear_four_text > div img{
    pointer-events:none;
  }
  .link-container {
    display: block;
    width: 100%;
    height: 100%;
  }
}
