@media (min-width: 800px) {
  .approval_title {
    width: 1400px;
    text-align: center;
  }

  .approval_title h1 {
    display: inline-block;
    font-size: 30px;
    font-weight: bolder;
    color: #05304f;
    padding: 5px 15px;
    border-bottom: 4px solid #05304f;
  }

  .approval_title p {
    font-size: 15px;
    line-height: 70px;
  }
  .approval_big_center {
    width: 1400px;
  }
  .approval_big {
    width: 100%;
    height: 800px;
    background-color: #042e4e;
    margin-bottom: 55px;
  }

  .approval_text {
    width: 1400px;
    overflow: hidden;
    height: 200px;
  }

  .approval_text > div {
    float: left;
    height: 200px;
  }

  .appreval_report {
    width: 850px;
    color: white;
  }

  .appreval_report h1 {
    color: white;
    font-size: 28px;
    font-weight: bolder;
    margin-top: 65px;
  }

  .appreval_report p {
    font-style: italic;
    font-size: 17px;
    line-height: 20px;
    margin-top: 20px;
  }

  .appreval_logo {
    width: 310px;
    height: 74px;
    margin-top: 75px;
    margin-left: 170px;
  }

  .approval_world {
    width: 1400px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 60px;
    flex-wrap: wrap;
  }

  .approval_world > div {
    width: 310px;
    height: 180px;
    color: white;
  }
  .approval_world h1 {
    font-size: 28px;
    font-weight: bolder;
    color: white;
    margin-bottom: 20px;
  }
  .approval_world p {
    line-height: 24px;
    font-size: 19px;
  }
  .xian {
    width: 1400px;
    height: 1px;
    background-color: white;
  }
  .approval_bottom {
    overflow: hidden;
    width: 1400px;
    margin-top: 20px;
  }
  .approval_bottom img {
    width: 238px;
    height: 140px;
    float: left;
    margin-left: 50px;
  }
  .approval_bottom_text {
    float: left;
    color: white;
    margin-left: 20px;
    margin-top: 20px;
  }
  .Germany {
    font-size: 22px;
    font-weight: 700;
  }
  .Germany_text {
    font-size: 16px;
  }
  .Germany_title {
    font-size: 24px;
    font-weight: bolder;
    line-height: 75px;
  }
  .approval_bottom1 {
    overflow: hidden;
    width: 1400px;
    margin-top: 20px;
  }
  .approval_bottom_text1 {
    float: left;
    margin-left: 50px;
    color: white;
  }
  .approval_bottom1 img {
    width: 238px;
    height: 140px;
    float: left;
    margin-left: 383px;
    margin-top: 20px;
  }
  .approval_famous {
    font-size: 22px;
    font-weight: 700;
    margin-top: 40px;
  }
  .approval_year {
    font-size: 24px;
    font-weight: bolder;
    line-height: 75px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .approval_title h1 {
    font-size: 60px;
  }
  .approval_big{
    height: 1800px;
    margin-bottom: 200px;
  }
  .approval_title {
    margin-top: 50px;
  }
  .approval_title p {
    font-size: 42px;
  }
  .approval_big_center {
    width: 90%;
  }
  .approval_text > div {
    height: 400px;
  }
  .approval_text {
    height: 400px;
    width: 100%;
  }
  .appreval_report{
    width: 70%;
  }
  .appreval_report h1 {
    font-size: 48px;
  }
  .appreval_report p{
    font-size: 38px;
    line-height: 48px;
  }
  .appreval_logo{
    margin-left: 30px;
    margin-top: 150px;
    width: 420px;
  }
  .approval_world{
    width: 100%;
  }
  .approval_world > div{
    width: 48%;
    height: 300px;
  }
  .approval_world h1{
    font-size: 45px;
  }
  .approval_world p{
    font-size: 38px;
    line-height: 48px;
  }
  .xian{
    width: 100%;
  }
  .approval_bottom{
    width: 100%;
  }
  .approval_bottom img{
    width: 30%;
    height: 30%;
    margin-left: 0;
  }
  .approval_bottom_text{
    width: 68%;
  }
  .Germany,.approval_famous{
    font-size: 43px;
  }
  .Germany_title,.approval_year{
    font-size: 38px;
  }
  .Germany_text{
    font-size: 34px;
  }
  .approval_bottom1{
    width: 100%;
  }
  .approval_bottom_text1{
    margin-left: 0;
    width: 68%;
    margin-top: 60px;
  }
  .approval_bottom1 img{
    margin-left: 0;
  }
  .approval_bottom1 img{
    width: 30%;
    height: 30%;
    margin-top: 0;
  }
}
