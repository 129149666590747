@media (min-width: 800px) {
  .information{
    width: 1400px;
    margin: auto;
    margin-top: 30px;
    display: flex;
    margin-bottom: 100px;
  }
  .information>div{
    flex: 1;
    overflow: hidden;
  }
  .information_title{
    width: 1400px;
    margin: auto;
    font-size: 19px;
    font-weight: 600;
    padding: 0px;
    /* margin-bottom: 18px; */
    color: #222;
    margin-top: 130px;
  }
  .icon_item{
    height: 82px;
    width: 82px;
    border-radius: 41px;
    background-color: #f9f9f9;
    color: #1086df;
    font-size: 40px;
    padding-top: 20px;
    text-align: center;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .information_left{
    display: flex;
    margin-top: 20px;
    /* justify-content: center; */
  }
  .information_box{
    display: flex;
  }
  .icon-daohangdizhi,.icon-dianhua,.icon-a-youjianchakanyoujianfasongyoujianshouyoujian-06{
    font-size: 35px;
    color: #1086df;
  }
  .icon_item_text{
    float: left;
  }
  .icon_item_title{
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    margin-top: 9px;
    margin-left: 18px;
  }
  .icon_item_description{
    font-size: 13px;
    font-weight: 300;
    color: #727272;
    margin-left: 18px;
    margin-top: 17px;
  }
  .icon_item_description2{
    font-size: 13px;
    font-weight: 300;
    color: #727272;
    margin-left: 18px;
    margin-top: 10px;
  }
  .g601{
    width: 45%;
    height: 200px;
    object-fit: cover;
    float: left;
    margin-right: 30px;
    margin-top: 30px;
  }
}
@media (min-width:400px) and (max-width:1000px){
  .information_title{
    width: 90%;
    font-size: 50px;
  }
  .information{
    width: 90%;
    flex-flow: column;
    flex-wrap: wrap;
  }
  .icon_item{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    padding-top: 65px;
  }
  .icon-daohangdizhi,.icon-dianhua,.icon-a-youjianchakanyoujianfasongyoujianshouyoujian-06{
    font-size: 75px;
  }
  .icon_item_title{
    font-size: 50px;
    margin-top: 20px;
  }
  .icon_item_description{
    font-size: 45px;
    margin-top: 36px;
  }
  .icon_item_description2{
    font-size: 45px;
    margin-top: 26px;
  }
  .g601{
    width: 100%;
    height: 900px;
  }
}