@media (min-width: 800px) {
  .input_four {
    width: 1400px;
    position: relative;
  }

  .input_big {
    width: 100%;
  }

  .input_check_center {
    width: 1400px;
    height: 80px;
    overflow: hidden;
  }

  .inputch_intput {
    margin-left: 400px;
    margin-top: 20px;
  }

  .input_check_center > div {
    float: left;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
  }

  .inputch_background {
    background-color: #b3b3b3;
  }

  .check {
    position: absolute;
    top: -18px;
    right: 0px;
  }

  .input_check_center img {
    height: 60px;
    width: 90%;
  }

  .input_check {
    width: 100%;
    height: 80px;
    background-color: #eeeeee;
    margin-top: 20px;
  }

  .input_four_text img {
    width: 320px;
    height: 250px;
  }

  .input_button {
    background-color: #3d8bcd;
    color: white;
    border-style: none;
    padding: 10px 60px;
    border-radius: 6px;
    margin-top: 80%;
    margin-left: 30%;
    font-size: 16px;
  }

  .input_four_text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    position: relative;
  }

  .input_four_box {
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
  }

  .input_four_box > div {
    width: 320px;
    height: 250px;
  }
  .input_four_box > div:not(:nth-child(4n + 0)) {
    margin-right: 40px;
  }
  .input_four_box img {
    position: absolute;
    width: 320px;
    object-fit: cover;
    box-shadow: 5px 5px 5px rgba(153, 153, 153, 0.863);
  }
  .tech_box:hover {
    transform: scale(1.1);
  }
  .tech_box {
    position: relative;
    margin-bottom: 40px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .tech_text {
    position: absolute;
    color: white;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
    padding: 10px 10px;
    bottom: 0px;
  }

  .tech_text h1 {
    font-size: 16px;
    color: white;
    line-height: 21px;
    margin-bottom: 6px;
    font-weight: bolder;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .tech_text p {
    font-size: 12px;
    line-height: 16px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .input_four_button {
    background-color: #3d8bcd;
    color: white;
    border-style: none;
    padding: 10px 60px;
    border-radius: 6px;
    font-size: 16px;
    margin-left: 45%;
  }

  .ant-input-group-wrapper {
    width: 300px !important;
    margin-left: 550px;
    margin-top: 20px;
  }

  .ant-btn-primary {
    background-color: #ffffff !important;
    color: #b3b3b3 !important;
    border: 1px solid #b3b3b3 !important;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .input_check {
    height: 190px;
  }
  .input_check_center {
    width: 91%;
    height: 190px;
  }
  .input_check_center img {
    width: 4rem;
    height: 140px;
    margin-top: 10px;
  }
  .input_four {
    width: 90%;
    /* height: 900px; */
  }
  .input_four_box > div{
    width: 100%;
    height: 900px;
  }
  .tech_box{
    width: 100%;
    height: 900px;
    margin-right: 0 !important;
    cursor: pointer;
  }
  .tech_box:hover {
    transform: none;
  }
  .input_four_box{
    width: 100%;
  }
  .input_four_box img{
    width: 100%;
    height: 900px;
  }
  .tech_text{
    width: 100%;
  }
  .tech_text h1{
    font-size: 54px;
    line-height: 65px;
  }
  .input_four_button{
    padding: 30px 130px;
    margin-left: 38%;
    font-size: 39px;
    border-radius: 30px;
  }
  .link-container {
    display: block;
    width: 100%;
    height: 100%;
  }
}
