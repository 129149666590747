@media (min-width: 800px) {
  .pro_customer_Exhibition1 {
    width: 1400px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .pro_customer_Exhibition1 div:not(:nth-child(10n + 0)) {
    margin-right: 22px;
  }

  .customer_img {
    width: 120px;
  }

  .pro_customer_Exhibition1 > div {
    width: 120px;
    text-align: center;
    margin-bottom: 20px;
  }

  .customer_pic_box {
    width: 120px;
    text-align: center;
    display: block;
  }

  .customer_more {
    display: none;
    width: 120px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .pro_customer_Exhibition1 {
    width: 100%;
  }
  .pro_customer_Exhibition1>div{
    width: 20%;
}
.pro_customer_Exhibition1 div:not(:nth-child(5n+0)),.pro_customer_Exhibition1 div:not(:nth-child(10n+0)){
    margin-right: 0;
}
}
