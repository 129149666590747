@media (min-width: 800px) {
  .time_big {
    width: 1400px;
    height: 666px;
    overflow: hidden;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  .time_left {
    width: 800px;
    height: 620px;
    position: relative;
  }
  .ant-timeline {
    padding-top: 9px !important;
  }

  .time_right_img {
    width: 555px;
    position: absolute;
  }

  .time_right {
    width: 555px;
    position: relative;
  }
  .time_text {
    overflow: hidden;
    cursor: pointer;
    margin-top: 0px;
    padding-left: 35px;
    cursor: pointer;
  }
  .time_text > div {
    float: left;
  }
  .time_data {
    color: #1890ff;
    font-size: 17px;
    font-weight: bolder;
    /* margin-top: -5px; */
  }
  .time_world {
    width: 626px;
    height: 147px;
    font-size: 14px;
    padding-top: 2px;
    color: #727272;
    padding-left: 22px;
  }
  .ant-timeline-item-head {
    width: 20px !important;
    height: 20px !important;
  }
  .ant-timeline-item-tail {
    left: 9px !important;
  }
  .time_left_img {
    height: 65px;
    position: absolute;
    left: 133px;
    top: 73px;
    /* margin: 12px 100px; */
  }
  .CheckCircleOutlined {
    font-size: 16px;
    margin-left: 6px;
    background-color: #1890ff;
    border-radius: 50%;
  }
  .time_world:hover {
    text-decoration: underline;
    font-weight: bolder;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .time_big {
    width: 100%;
    height: 1000px;
  }
  .time_right {
    width: 40%;
  }
  .time_right_img {
    width: 100%;
    /* height: 1000px; */
  }
  .time_left{
    height: 1200px;
    width: 60%;
  }
  .time_data{
    font-size: 55px
  }
  .time_world{
    font-size: 36px;
    padding-top: 10px;
  }
  .time_world{
    width: 900px;
    height: 230px;
  }
  .time_left_img{
    height: 100px;
    top: 160px;
    left: 290px;
  }
  .ant-timeline-item-head{
    width: 30px !important;
    height: 30px !important;
  }
  .CheckCircleOutlined{
    font-size: 10px;
    z-index: 99;
  }
  .ant-timeline-item-head-custom svg{
    font-size: 30px;
    margin-top: 0;
    z-index: -1;
    color: #1890ff;
    font-size: 25px !important;
  }
}
