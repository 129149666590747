@media screen and (min-width: 800px){
  .carousel_box {
    width: 1400px;
    height: 660px;
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
  }

  .carousel {
    width: 1020px;
    height: 660px;
    font-size: 0;
  }

  .carousel img {
    height: 660px;
    width: 100%;
  }

  .active2 {
    background-color: #05304f;
    width: 380px;
    height: 132px;
    color: white !important;
  }

  .carousel_text {
    width: 380px;
    height: 660px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-content: space-between;
    box-sizing: border-box;
  }
  .carousel-click {
    cursor: pointer;
  }

  .carousel_text > div {
    width: 380px;
    height: 132px;
    background-color: #eeeeee;
  }

  .slick-next:before {
    display: none;
  }

  .carousel_title {
    font-size: 17px !important;
    font-weight: bolder;
    line-height: 50px;
    padding: 17px 25px;
  }
  .carousel_text > div a {
    color: #333;
  }
  .carousel_text p {
    font-size: 12px;
    line-height: 19px;
  }

  .carousel_text_word {
    padding: 0 25px;
  }

  .loadingCarousel {
    width: 1400px;
    height: 660px;
    text-align: center;
    font-size: 14px;
  }
  .loadingCarousel > img {
    margin-top: 300px;
    width: 100px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 400px) and (max-width: 1000px) {
  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel_box {
    width: 100%;
    height: 100%;
    display: block;
  }
  .carousel_text {
    width: 100%;
    height: 1500px;
    /* display: none; */
  }
  .carousel img {
    width: 100%;
    height: 1000px;
  }
  .slickSlider {
    height: 10rem;
  }
  .carousel_text > div{
    width: 100%;
    height: 300px;
  }
  .active2{
    width: 100%;
    height: 300px;
  }
  .carousel_text p{
    font-size: 45px;
    line-height: 60px;
  }
  .carousel_title{
    font-size: 55px !important;
  }
}
