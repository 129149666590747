@media (min-width: 800px) {
  .power-work-div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .power-work-div .flex {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .power-work-div .pro {
    position: relative;
    width: 1400px;
  }
  .power_text {
    position: absolute;
    z-index: 99;
    width: 1400px;
    color: #042e4e;
    height: 700px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 30%, #f5f7f8 130%);
  }
  .power_text_box {
    width: 700px;
    margin-top: 300px;
    padding-left: 90px;
  }
  
  .power_text_title {
    display: block;
    font-size: 30px;
    font-weight: bolder;
    line-height: 77px;
    color: #042e4e;
  }
  
  .power_text p:hover {
    text-decoration: underline;
  }
  
  .power_text p {
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
  }
  .power_big {
    width: 1400px;
    height: 700px;
    position: relative;
  }
  .power-logo-text{
    overflow: hidden;
    width: 1400px;
    height: 90px;
  }
  .pro-logo-powerimg {
    float: left;
    width: 275px;
    height: 70px;
    margin-right: 30px;
    margin-top: 10px;
  }
  .pro-logo-power {
    float: left;
    height: 48px;
    font-size: 27px;
    font-weight: bolder;
    border-left: 3px solid rgb(204, 229, 255);
    line-height: 48px;
    margin-top: 23px;
    padding-left: 30px;
    margin-right: 30px;
  }
  .power-logo-href{
    font-size: 16px;
    margin-top: 36px;
    cursor: pointer;
  }

  .power-detail-text{
    width: 100%;
    margin-top: -25px;
  }
  .power-detail-center{
    width: 100%;
    background-color: #eeeeee;
  }
  .power_center {
    width: 1600px;
  }
  .power_logo {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
    margin-left: 2.5rem;
    width: 15rem;
    padding: 0.2rem;
    position: relative;
  }
  .power-left-img {
    width: 7.2rem;
    height: 4.2rem;
  }
  .power-right{
    width: 5.57rem;
    position: absolute;
    right: 1.6rem;
    top: 0.3rem;
  }
  .power-right-text{
    width: 6.3rem;
  }
  .power-right h1{
    font-size: 37px;
    font-weight: bolder;
    line-height: 0.4rem;
  }
  .power-right p{
    margin-top: 15px;
    font-size: 0.18rem;
    line-height: 0.28rem;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    width: 6.3rem;
    font-weight: 600;
  }
  .power-right-icon{
    width: 4.2rem;
    color: #042e4e;
    font-size: 0.17rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.4rem;
  }
  .icon {
    border: 4px solid #053052;
    padding: 0.17rem;
    border-radius: 60px;
  }
  .power-background{
    background-color: #eeeeee;
    width: 100%;
    height: 2.8rem;
    display: flex;
    padding: 50px 120px;
    justify-content: space-evenly;
  }
  .power-long-content{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  .power-first-content{
    width: 1400px;
  }
  .power-content-top{
    width: 1400px;
    text-align: center;
    color: #053052;
  }
  .power-content-top h1 {
    font-size: 35px;
    font-weight: bolder;
    line-height: 0.6rem;
    color: #053052;
  }
  .power-content-top p{
    width: 14rem;
    font-size: 0.23rem;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
  }
  .power-Details {
    display: flex;
    width: 14rem;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 50px;
  }
  .power-details-img {
    margin-right: 0.2rem;
    width: 5.5rem;
  }
  .details-img-lpa {
    width: 7.5rem;
    height: 4.5rem;
    margin: 50px 0 0 20px;
  }
  .power-Details h1{
    font-size: 30px;
    font-weight: bolder;
    color: #011b2eda;
    margin: 20px 0;
  }
  .power-Details div{
    font-size: 19px;
    line-height: 0.4rem;
  }
  .power-Details span {
    font-weight: bold;
    font-size: 19px;
  }
  .power-content-img{
    width: 100%;
    height: 100%;
  }
  .power-dividing-line{
    width: 300px;
    height: 7px;
    background-color: #011b2eda;
  }
  .power-second-content{
    width: 1400px;
    display: flex;
    justify-content: flex-start;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .power-work-div {
    width: 100%; 
  }
  .power-work-div .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    width: 100vw;
  }
  .power-work-div .pro {
    width: 1900px;
  }
  .power_text {
    position: absolute;
    z-index: 99;
    width: 1400px;
    color: #042e4e;
    height: 700px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 30%, #f5f7f8 130%);
  }
  .power_text_box {
    width: 700px;
    margin-top: 300px;
    padding-left: 90px;
  }
  .sear_four_text {
    width: 17rem;
    height: 5rem;
  }
  
  .power_text_title {
    display: block;
    font-size: 30px;
    font-weight: bolder;
    line-height: 77px;
    color: #042e4e;
  }
  
  .power_text p:hover {
    text-decoration: underline;
  }
  
  .power_text p {
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
  }
  .power_big {
    width: 100%;
    height: 900px;
    position: relative;
  }
  .power-logo-text{
    display: flex;
    margin: 10px 0;
    width: 100%;
    height: 1.6rem;
  }
  .power-logo-text p {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 34px;
    margin: 0 0 0 19px;
  }
  .pro-logo-powerimg {
    float: left;
    width: 5.3rem;
    height: 1.3rem;
  }
  .pro-logo-power {
    border-left: 0;
    float: left;
    font-size: 56px;
    font-weight: bolder;
    line-height: 1rem;
    margin: 23px 0 0 0;
  }
  
  .power-detail-text{
    width: 100%;
  }
  .power_center {
    width: 1600px;
  }
  .power_logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 18rem;
    padding: 60px 40px 20px; 
    height: auto;
    margin: 20px 10px 0 10px;
    position: relative;
  }
  .power-left-img {
    width: 11rem;
    height: 6rem;
  }
  .power-right{
    width: 100%;
    position: static;
  }
  .power-right-text{
    width: 100%;
  }
  .power-right h1{
    font-size: 0.44rem;
    font-weight: bolder;
    line-height: 0.7rem;
    margin: 10px 0 0 3.82rem;
  }
  .power-right p{
    width: 100%;
    font-size: 0.38rem;
    line-height: 0.37rem;
  }
  .power-right-text p {
    font-weight: 400;
    line-height: 0.47rem;
  }
  .power-right-icon{
    width: 100%;
    color: #042e4e;
    font-size: 0.4rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0.5rem 0;
  }
  .icon {
    border: 0.06rem solid #053052;
    padding: 0.2rem;
    border-radius: 0.6rem;
  }
  .power-background{
    background-color: #eeeeee;
    width: 100%;
    height: auto;
    padding: 40px 15px;
  }

  .power-long-content{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }
  .power-first-content{
    width: 1700px;
  }
  .power-content-top{
    width: 1700px;
    text-align: center;
    color: #011b2eda;
  }
  .power-content-top h1 {
    font-size: 0.75rem;
    font-weight: bolder;
    line-height: 1.3rem;
  }
  .power-content-top p{
    width: 17rem;
    font-size: 44px;
    font-weight: 500;
    line-height: 57px;
    text-align: left;
  }
  .power-Details {
    display: flex;
    width: 17rem;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 0.5rem;
  }
  .power-details-img {
    width: 6.5rem;
  }
  .details-img-lpa {
    width: 8.5rem;
    height: 5.5rem;
    margin: 40px 0 0 20px;
  }
  .power-Details h1{
    font-size: 56px;
    font-weight: bolder;
    color: #011b2eda;
    margin: 0.2rem 0;
  }
  .power-Details div{
    font-size: 44px;
    line-height: 57px;
    font-weight: 400;
  }
  .power-Details span {
    font-weight: bold;
    font-size: 44px;
  }
  .power-content-img{
    width: 100%;
    height: 100%;
  }
  .power-dividing-line{
    width: 5rem;
    height: 0.15rem;
    background-color: #011b2eda;
  }
  .power-second-content{
    width: 17rem;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .power-work-div .pro_new_tittle {
    width: 100%;
  }
  .power-work-div .pro_new_tittle h1 {
    font-size: 60px;
  }
}
