@media (min-width: 800px){
  .head {
    width: 1400px;
    height: 70px;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  ul {
    overflow: hidden;
    flex: 1;
  }
  
  .header li {
    float: left;
    font-size: 14px;
    color: black;
  }
  
  .logo_box {
    width: 570px;
  }
  
  .logo {
    width: 120px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
  .app {
    position: relative;
  }
  .top {
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: #001529;
    top: 0;
  }
  .news {
    width: 1400px;
    position: relative;
  }
  .ant-menu-item-selected::after {
    border-bottom: 4px solid #001529 !important;
  }
  .ant-menu-item-selected::after:hover {
    border: 1px solid #001529 !important;
  }
  .ant-menu-item-selected {
    color: #001529 !important;
  }
  .ant-menu-title-content:hover {
    color: #001529 !important;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 50px !important;
  }
  
  .top_relation {
    width: 1400px;
    font-size: 11px;
    line-height: 30px;
  }
  .top_relation span {
    color: white;
    margin-right: 20px;
  }
  .top_relation img {
    width: 16px;
    vertical-align: middle;
    margin-right: 3px;
  }
  .relation:hover {
    text-decoration: underline;
    color: rgb(146, 145, 145);
    cursor: pointer;
  }
  .ant-menu-item {
    margin: 0 !important;
    padding: 0 !important;
  }
  .center {
    width: 1400px;
  }
}

@media (min-width: 400px) and (max-width: 1000px){
  .top{
    height: 80px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .top_relation{
    width: 100%;
    font-size: 40px;
    line-height: 60px;
  }
  .top_relation img{
    width: 48px;
    margin-right: 9px;
  }
  .header{
    /* min-width: 370px; */
    width: 95%;
  }
  .logo_box{
    width: 100%;
  }
  .logo{
    width: 360px;
  }
  .anticon svg{
    font-size: 130px !important;
    color: #001529;
    margin-top: 60px;
  }
  .ant-dropdown{
   width: 100%;
  }
  .ant-dropdown-menu-title-content{
    height: 90px;
    font-size: 60px;
    line-height: 90px;
  }
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    padding: 10px 16px;
    font-weight: 700;
    background: #0a98ce;
    color: white !important;
  }



  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
    background: #005e9e !important;
    color: white !important;
  }
}