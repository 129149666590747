@media (min-width: 800px) {
  .content_box {
    overflow: hidden;
    position: relative;
    width: 1400px;
  }

  .content_box > div {
    float: left;
  }

  .content_box > div:nth-child(1) {
    width: 930px;
    height: 530px;
    position: relative;
    font-size: 17px;
    color: white;
    float: left;
  }

  .content_box > div:nth-child(1) div {
    width: 930px;
    position: absolute;
    padding: 10px 40px;
    bottom: 0px;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
  }

  .content_box > div:nth-child(1) h1 {
    font-size: 22px;
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .content_box > div:nth-child(1) p {
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
  }

  .content_box > div:nth-child(1) img {
    width: 930px;
    height: 530px;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
  }

  .content_box > div:not(:nth-child(1)) {
    width: 450px;
    height: 255px;
    margin-left: 20px;
    font-size: 14px;
    position: relative;
    color: white;
    line-height: 17px;
  }

  .content_box > div:not(:nth-child(1)) h1 {
    color: white;
    font-size: 17px;
    margin-bottom: 5px;
    line-height: 19px;
    font-weight: bold;
  }

  .content_box > div:not(:nth-child(1)) p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
  }

  .content_box > div:not(:nth-child(1)) div {
    width: 450px;
    position: absolute;
    padding: 10px 15px;
    bottom: 0px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #003254 100%);
  }

  .content_box > div:nth-child(2) {
    margin-bottom: 20px;
  }

  .content_box > div:not(:nth-child(1)) img {
    width: 450px;
    height: 255px;
    object-fit: cover;
  }
  .newtech_loading {
    width: 1400px;
    height: 525px;
    text-align: center;
    font-size: 14px;
  }
  .newtech_loading img {
    width: 60px;
    margin-top: 200px;
    margin-bottom: 10px;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  .content_box {
    width: 90%;
  }
  .content_box > div {
    width: 100% !important;
    height: 900px !important;
    margin-left: 0 !important;
    margin-bottom: 40px;
  }
  .content_box > div img {
    width: 100% !important;
    height: 100% !important;
  }
  .content_box_text {
    width: 100% !important;
  }
  .content_box_text > h1 {
    font-size: 55px !important;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 60px !important;
  }
  .content_box_text > p{
    font-size: 40px;
    line-height: 55px !important;
  }
}
