@media (min-width: 800px) {
  .footer {
    background-color: #042e4e;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .footer h1 {
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-weight: bolder;
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .footer_input {
    padding-bottom: 30px !important;
    width: 100%;
    overflow: hidden;
    background-color: #042e4e;
    color: white;
    border-bottom: 1px solid white;
  }
  .submit_box {
    width: 100px !important;
    line-height: 32px !important;
    text-align: center !important;
  }
  .submit_box button {
    color: #fff !important;
    border-color: #1890ff !important;
    background: #1890ff !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    line-height: 8px !important;
    margin-left: -16px !important;
  }
  .ant-form-inline .ant-form-item{
    margin-right: 115px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 300px !important;
    height: 40px !important;
    border-radius: 8px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
  /* .ant-select-selection-item{
    padding-top: 5px !important;
  } */
  .footer_input > div {
    width: 400px;
    float: left;
    color: white;
    font-size: 17px;
    margin-bottom: 30px;
    /* margin-right: 30px; */
  }
  .footer_text>div{
    width: 300px;
  }
  .last_input {
    width: 700px !important;
  }
  .ant-select-arrow {
    left: 270px;
  }

  .footer_input input {
    width: 300px;
    height: 40px;
    border-radius: 8px;
    color: #333;
    outline: none;
    text-indent: 15px;
    border: none;
    margin-right: 30px;
  }
  .footer_input button {
    background-color: #3d8bcd;
    border: none;
    padding: 12px 57px;
    border-radius: 6px;
    margin-left: 57px;
  }
  .footer_input p {
    font-size: 15px;
    line-height: 32px;
  }
  .long_input {
    width: 368px;
  }
  .input_title {
    overflow: hidden;
    font-size: 15px;
    line-height: 32px;
    color: white;
    font-weight: bolder;
  }
  .input_title > div {
    float: left;
    width: 400px;
    margin-right: 15px;
  }
  .form-title {
    font-size: 15px;
    line-height: 32px;
    color: white;
    font-weight: bolder;
  }
  .checkbox {
    width: 150px !important;
  }
  .ant-checkbox + span {
    color: white;
  }
  .footer_text {
    margin-top: 40px;
    margin-right: 0px;
  }

  .euci {
    width: 90%;
    height: 70%;
    float: left;
    margin-right: 30px;
  }

  .footer_text h3 {
    color: white;
    font-weight: bolder;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .company {
    width: 250px;
    border-bottom: 1px solid white;
  }
  .company_desc {
    font-size: 54px;
    line-height: 64px;
  }
  .introduce {
    margin-bottom: 0 !important;
  }

  .footer_text li {
    clear: both;
    color: white;
    list-style: disc !important;
    margin-left: 21px;
    font-size: 13px;
    line-height: 22px;
  }

  .introduce span {
    color: white;
    font-size: 13px;
    line-height: 22px !important;
  }
  .fast {
    width: 90px;
    border-bottom: 1px solid white;
  }
  .fast_box {
    padding-left: 70px;
  }
  .partner {
    overflow: hidden;
    width: 320px !important;
  }
  .partner > h3 {
    width: 90px;
    border-bottom: 1px solid white;
  }

  .new {
    position: relative;
    width: 160px !important;
  }
  .kisters {
    height: 35px;
    float: left;
    width: 150px;
    margin-top: 14px;
  }

  .contact {
    float: left;
    height: 42px;
    margin-top: 10px;
    margin-right: 20px;
  }
  .analytics {
    float: left;
    height: 0.32rem;
    margin-top: 0.3rem;
    margin-right: 0.2rem;
  }
  .new > h3 {
    width: 100px;
    border-bottom: 1px solid white;
  }
  .code {
    width: 83px;
  }
  .wx {
    font-size: 14px !important;
  }
  .bottom {
    font-size: 14px;
    height: 36px;
    width: 100%;
    margin: 19px 0;
    text-align: center;
    font-weight: bolder;
  }
  .bottom img {
    padding: 0 10px;
    vertical-align: middle;
  }
  .a_one:visited {
    color: #551a8b;
  }
  .a_two {
    color: #333;
    font-weight: bolder;
  }
  .icon-gengduo {
    font-size: 15px;
  }
  .fast_box a {
    color: white;
  }
  .fast_box a:hover {
    text-decoration: underline;
    color: white;
  }
  .fast_box li:hover {
    color: white;
  }
}
@media (min-width: 400px) and (max-width: 1000px) {
  /* .center{
    width: 80%;
  } */
  /* .footer_center{
    width: 90%;
  } */
  .footer_center {
    width: 90%;
  }
  .footer h1 {
    font-size: 58px;
    margin-top: 20px;
  }
  .anticon svg {
    font-size: 70px;
    color: #042e4e;
  }
  .ant-select-arrow {
    left: 1560px;
    top: 10px !important;
  }
  .footer_input > div {
    width: 100% !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100% !important;
    height: 100px !important;
    line-height: 100px;
    font-size: 45px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 100px !important;
  }
  .footer_title_span {
    color: white;
    font-size: 53px;
    margin-top: 20px;
  }
  .ant-form-item-control {
    width: 100% !important;
  }
  .produceCon{
    margin-bottom: 12px !important;
  }
  .footer_input input {
    margin-bottom: 12px;
    width: 100%;
    height: 100px;
    line-height: 100px;
    font-size: 45px;
  }
  .ant-select-item-option-content {
    font-size: 45px;
  }
  .ant-col-offset-8 {
    margin-left: 0 !important;
  }
  .checkbox {
    width: 100% !important;
  }
  .ant-form label {
    font-size: 40px !important;
    margin-top: 15px;
  }
  .footer_input button {
    margin-left: 0;
    font-size: 48px;
    padding: 50px 80px;
    border-radius: 15px;
    width: 100%;
  }
  .ant-col-16 {
    max-width: 100% !important;
  }
  .footer_text h3 {
    font-size: 59px;
    border: none !important;
    width: 100%;
    font-weight: bolder;
    margin: 30px 0;
  }
  .footer_logo {
    position: relative;
  }
  .company {
    font-size: 66px !important;
  }
  .introduce span {
    font-size: 45px;
    line-height: 65px !important;
  }
  .fast_box {
    padding-left: 0;
    margin-top: 30px;
    position: relative;
  }
  .new {
    width: 40% !important;
    position: absolute;
    top: 0;
    right: 50px;
  }
  .footer_text li {
    font-size: 49px;
    line-height: 65px;
  }
  .code {
    width: 40%;
  }
  .wx {
    font-size: 45px !important;
    line-height: 55px !important;
  }
  .contact {
    width: 30%;
    height: 30%;
    margin-bottom: 40px;
  }
  .kisters {
    width: 35%;
    height: 35%;
    margin-top: 40px;
  }
  .analytics {
    width: 30%;
    height: 30%;
    margin-top: 40px;
  }
  .bottom {
    font-size: 45px;
  }
  .anticon svg {
    font-size: 80px !important;
  }
  .company_desc > .company_name {
    font-size: 66px !important;
    line-height: 75px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .company_desc {
    font-size: 58px;
    line-height: 70px;
  }
  .euci {
    width: 50%;
    height: 50%;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}
