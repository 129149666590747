@media (min-width: 800px){
  .content_big {
    width: 1200px;
    margin: auto;
    padding: 20px 30px;
  }
  .news_title {
    font-size: 30px;
    margin: 15px 0;
  }
  .news_message {
    display: flex;
    font-size: 14px;
    margin-bottom: 30px;
    color: #909090;
  }
  .news_message > div {
    margin-right: 15px;
  }
  .news_pic {
    width: 1200px;
    object-fit: cover;
  }
  .news_content {
    font-size: 14px;
    line-height: 30px;
  }
  .news_content img{
    max-width: 100%;
  }
  .media-wrap,
  .image-wrap {
    width: 1200px;
  }
  .bread{
    width: 1150px;
    margin: auto;
  }
  
}
@media (min-width: 300px) and (max-width: 1000px){
  .content_big{
    width: 90%;
  }
  .bread{
    width: 90%;
    padding-top: 20px;
  }
  .ant-breadcrumb>span{
    font-size: 50px;
  }
  .news_title{
    font-size: 50px;
  }
  .news_message{
    font-size: 36px;
  }
  .news_content{
    font-size: 50px;
    line-height: 57px;
  }
}
